import { ISO8601Date, ISO8601DateTime } from '../types';

export const serviceVersion = '2023-02-15';

export type ServiceParams = {
	listUserActivity: {
		userId: string;
		timezone: string;
		includeResetActivity: boolean;
		order: 'oldest_first' | 'newest_first';
		filter: null | {
			types: null | FilterType[];
		};
	};
	resetAllProgress: {
		userId: string;
	};
	recordActivityLearningCompleted: {
		userId: string;
		timezone: string;
		params: {
			activityId: string;
		};
	};
	recordActivityCompleted: {
		userId: string;
		timezone: string;
		params: {
			activityId: string;
			activityVariantId: string;
			context: {
				choices: Record<string, string>;
				pelvicFloorSqueeze?: PelvicFloorSqueeze;
			};
		};
	};
};

export type ServiceResponses = {
	listUserActivity: Activity[];
	resetAllProgress: void;
	recordActivityLearningCompleted: ActivityLearningCompletedItem;
	recordActivityCompleted: ActivityCompletedItem;
};

export type PelvicFloorSqueeze =
	| PelvicFloorSqueezeCalibrationParams
	| PelvicFloorSqueezeRepeatParams;

type PelvicFloorSqueezeCalibrationParams = {
	type: 'calibration';
	reps: number;
	duration: number;
};

type PelvicFloorSqueezeRepeatParams = {
	type: 'repeat';
	reps: null;
	duration: null;
};

type Activity = ActivityLearningCompletedItem | ActivityCompletedItem;

type BaseItem = {
	id: string;
	type: string;
	createdAt: ISO8601DateTime;
	trackingDate: ISO8601Date;
	params: Record<string, unknown>;
};

type ActivityLearningCompletedItem = BaseItem & {
	type: 'activity_learning_completed';
	params: {
		activityId: string;
	};
};

export type ActivityCompletedItem = BaseItem & {
	type: 'activity_completed';
	// zero guarantees about anything inside params
	params: {
		activityId: string;
		activityVariantId: string | null;
		// context is an object, but we can't tell what's inside
		// strictly follow the backend docs for typing
		context: Record<string, unknown>;
	};
};

type FilterType = Activity['type'];
