import Service from './Service';
import * as analyticsevent from './services/analyticsevent';
import * as attribution from './services/attribution';
import * as authcredapple from './services/authcredapple';
import * as authcredemail from './services/authcredemail';
import * as authcredgoogle from './services/authcredgoogle';
import * as authsession from './services/authsession';
import * as authuser from './services/authuser';
import * as authuserdeletion from './services/authuserdeletion';
import * as authuseridentifier from './services/authuseridentifier';
import * as authuserimpersonation from './services/authuserimpersonation';
import * as clientsdkconfig from './services/clientsdkconfig';
import * as codeverifyemail from './services/codeverifyemail';
import * as communitycontent from './services/communitycontent';
import * as communityprofile from './services/communityprofile';
import * as communityreaction from './services/communityreaction';
import * as communitysso from './services/communitysso';
import * as contentvalidation from './services/contentvalidation';
import * as customeriosync from './services/customeriosync';
import * as dailytask from './services/dailytask';
import * as efficacymeasurement from './services/efficacymeasurement';
import * as experimentuserdata from './services/experimentuserdata';
import * as featureconfig from './services/featureconfig';
import * as inapppurchase from './services/inapppurchase';
import * as intercomconfig from './services/intercomconfig';
import * as learntracking from './services/learntracking';
import * as ping from './services/ping';
import * as poll from './services/poll';
import * as pseudonym from './services/pseudonym';
import * as pushnotification from './services/pushnotification';
import * as soundtracking from './services/soundtracking';
import * as staff from './services/staff';
import * as subscriptioncancelation from './services/subscriptioncancelation';
import * as subscriptioncheckout from './services/subscriptioncheckout';
import * as subscriptionstatus from './services/subscriptionstatus';
import * as testauthcred from './services/testauthcred';
import * as testauthuser from './services/testauthuser';
import * as testautomation from './services/testautomation';
import * as testsubscription from './services/testsubscription';
import * as trackingdate from './services/trackingdate';
import * as userpreference from './services/userpreference';

const ServiceVersions = {
	[Service.analyticsevent]: analyticsevent.serviceVersion,
	[Service.attribution]: attribution.serviceVersion,
	[Service.authcredapple]: authcredapple.serviceVersion,
	[Service.authcredemail]: authcredemail.serviceVersion,
	[Service.authcredgoogle]: authcredgoogle.serviceVersion,
	[Service.authsession]: authsession.serviceVersion,
	[Service.authuser]: authuser.serviceVersion,
	[Service.authuserdeletion]: authuserdeletion.serviceVersion,
	[Service.authuseridentifier]: authuseridentifier.serviceVersion,
	[Service.authuserimpersonation]: authuserimpersonation.serviceVersion,
	[Service.clientsdkconfig]: clientsdkconfig.serviceVersion,
	[Service.codeverifyemail]: codeverifyemail.serviceVersion,
	[Service.communitycontent]: communitycontent.serviceVersion,
	[Service.communityprofile]: communityprofile.serviceVersion,
	[Service.communityreaction]: communityreaction.serviceVersion,
	[Service.communitysso]: communitysso.serviceVersion,
	[Service.contentvalidation]: contentvalidation.serviceVersion,
	[Service.customeriosync]: customeriosync.serviceVersion,
	[Service.dailytask]: dailytask.serviceVersion,
	[Service.efficacymeasurement]: efficacymeasurement.serviceVersion,
	[Service.experimentuserdata]: experimentuserdata.serviceVersion,
	[Service.featureconfig]: featureconfig.serviceVersion,
	[Service.inapppurchase]: inapppurchase.serviceVersion,
	[Service.intercomconfig]: intercomconfig.serviceVersion,
	[Service.learntracking]: learntracking.serviceVersion,
	[Service.ping]: ping.serviceVersion,
	[Service.poll]: poll.serviceVersion,
	[Service.pseudonym]: pseudonym.serviceVersion,
	[Service.pushnotification]: pushnotification.serviceVersion,
	[Service.soundtracking]: soundtracking.serviceVersion,
	[Service.staff]: staff.serviceVersion,
	[Service.subscriptioncancelation]: subscriptioncancelation.serviceVersion,
	[Service.subscriptioncheckout]: subscriptioncheckout.serviceVersion,
	[Service.subscriptionstatus]: subscriptionstatus.serviceVersion,
	[Service.testauthcred]: testauthcred.serviceVersion,
	[Service.testauthuser]: testauthuser.serviceVersion,
	[Service.testautomation]: testautomation.serviceVersion,
	[Service.testsubscription]: testsubscription.serviceVersion,
	[Service.trackingdate]: trackingdate.serviceVersion,
	[Service.userpreference]: userpreference.serviceVersion,
};

export default ServiceVersions;
